import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import './scss/buddywise.scss';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import { PersistentStorageProvider } from './contextapi/PersistentStorageProvider';
import { initSentry } from './utils/Sentry';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

initSentry();

const phApiKey = process.env.REACT_APP_POSTHOG_API_KEY;
const phEnabled = localStorage.getItem('ph_enabled') === 'true';
if (phApiKey && phEnabled) {
  posthog.init(phApiKey, {
    api_host: 'https://eu.posthog.com',
    cross_subdomain_cookie: false,
    opt_out_persistence_by_default: true,
    opt_out_capturing_by_default: true,
    autocapture: false,
    capture_pageview: false,
  });
}
window._hsp.push([
  'addPrivacyConsentListener',
  (consent) => {
    const isAnalyticsAllowed = consent.categories.analytics;
    if (isAnalyticsAllowed) {
      posthog.opt_in_capturing();
    } else {
      window._hsq.push(['doNotTrack']);
      posthog.opt_out_capturing();
    }
  },
]);

root.render(
  <StrictMode>
    <PostHogProvider client={posthog}>
      <PersistentStorageProvider>
        <App />
      </PersistentStorageProvider>
    </PostHogProvider>
  </StrictMode>,
);
