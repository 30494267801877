import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

type LocationHistoryContextType = {
  route: {
    to: string;
    from: string;
  };
};

const LocationHistoryContext = createContext<LocationHistoryContextType | null>(
  null,
);

export const useLocationHistoryContext = () => {
  const context = useContext(LocationHistoryContext);
  if (!context) {
    throw new Error(
      'useLocationHistoryContext must be used within a LocationHistoryProvider',
    );
  }

  return context;
};

export const LocationHistoryProvider = ({ children }: PropsWithChildren) => {
  const location = useLocation();
  const [route, setRoute] = useState({
    to: location.pathname,
    from: location.pathname,
  });

  useEffect(() => {
    setRoute((prev) => ({
      to: `${location.pathname}${location.search}`,
      from: prev.to,
    }));
  }, [location]);

  const context = useMemo(() => ({ route }), [route]);

  return (
    <LocationHistoryContext.Provider value={context}>
      {children}
    </LocationHistoryContext.Provider>
  );
};
