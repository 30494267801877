import { useQuery } from '@apollo/client';
import i18n from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { updateAlertRule } from '../apis/api-request';
import { globalSiteId } from '../apis/tokens';
import { Breadcrumb } from '../components/elements/Breadcrumb';
import { BWButton } from '../components/elements/BWButton';
import { Loader } from '../components/elements/Loader';
import { ConditionsAction } from '../components/notification-actions/ConditionsAction';
import { MultiAlertAction } from '../components/notification-actions/MultiAlertAction';
import { NameOfAlertRuleAction } from '../components/notification-actions/NameOfAlertRuleAction';
import { Triggers } from '../components/notification-actions/Triggers';
import { useAuthContext } from '../contextapi/AuthProvider';
import { useCameraContext } from '../contextapi/CameraProvider';
import { useLocationHistoryContext } from '../contextapi/LocationHistoryProvider';
import { useScenarioContext } from '../contextapi/ScenarioProvider';
import { GET_ALERT_RULE_BY_ID } from '../graphql/queries/alerts';
import { useTrackEvent } from '../hooks/track_event';
import { Scenario } from '../lib/features/scenario';
import { CameraProps } from '../typescript/camera/camera';
import {
  AlertProps,
  filterActionArray,
  filterCameraArray,
  MultiActionProps,
} from '../typescript/smart-alert/smart-alert';

export function UpdateAlertRulePage() {
  const { route } = useLocationHistoryContext();
  const { userToken, onTokenSave } = useAuthContext();
  const { customerScenarios } = useScenarioContext();
  const { cameraList } = useCameraContext();
  const trackEvent = useTrackEvent();

  // Navigation
  const navigate = useNavigate();

  // Url Params
  const { slug } = useParams<{ slug: string }>();

  const { loading, error, data } = useQuery(GET_ALERT_RULE_BY_ID, {
    variables: {
      id: Number(slug),
    },
    fetchPolicy: 'network-only',
  });

  // CameraAction
  const [selectedCameraList, setSelectedCameraList] = useState<
    Array<CameraProps>
  >([]);

  // ConditionsAction
  const [selectedScenario, setSelectedScenario] = useState<Scenario | null>(
    null,
  );

  const [selectedNumber, setSelectedNumber] = useState<number | null>(null);

  const [selectedActionList, setSelectedActionList] = useState<
    Array<MultiActionProps>
  >([]);

  // IntervalAction
  const [selectedInterval, setSelectedInterval] = useState<{
    type: number;
    value: number;
  }>({ type: 1, value: 3600 });

  // NameOfAlertRuleAction
  const [alertName, setAlertName] = useState<
    string | number | string[] | undefined
  >();

  // Loader
  const [apiLoading, setApiLoading] = useState<boolean>(false);

  const getAlertRuleData = useCallback(async () => {
    if (!data) return;

    const rule: AlertProps = data.alert;
    const cameras = rule.alert_rule_cameras;
    const filterCamera = cameraList.filter((obj2) =>
      cameras.some((obj1) => obj1.camera_id === obj2.sql_id),
    );
    setSelectedCameraList(filterCamera);

    const filteredScenario = customerScenarios.filter(
      (scenario) => scenario.id === rule.customer_scenario_label_id,
    )[0];
    setSelectedScenario(filteredScenario);
    setSelectedNumber(rule.number_of_observations);

    if (rule.period <= 3599) {
      setSelectedInterval({ type: 0, value: rule.period });
    } else if (rule.period <= 86399) {
      setSelectedInterval({ type: 1, value: rule.period });
    } else {
      setSelectedInterval({ type: 2, value: rule.period });
    }

    const actions = rule.alert_actions.map((item) => {
      const userIds = item.alert_recipients
        .filter((filter) => filter.user_id)
        .map((recipient) => recipient.user_id) as Array<number>;
      const value = item.alert_recipients
        .filter((filter) => filter.value)
        .map((recipient) => recipient.value) as Array<string>;

      return {
        action: item.alert_site_channel,
        userIds: userIds.length !== 0 ? userIds : undefined,
        iot: value.length !== 0 ? value[0] : undefined,
        type:
          item.alert_site_channel.alert_channel.alert_type_name === 'iot'
            ? 1
            : 0,
      };
    });
    setSelectedActionList(actions);
    setAlertName(rule.name);
  }, [cameraList, data, customerScenarios]);

  useEffect(() => {
    if (data) {
      getAlertRuleData();
    }
  }, [data, getAlertRuleData]);

  const onUpdateAlertRule = useCallback(async () => {
    if (!data) return;

    switch (true) {
      case !selectedScenario:
        toast.error(i18n.t('toast.error.please_select_scenario'));
        break;
      case selectedCameraList.length === 0:
        toast.error(i18n.t('toast.error.please_select_cameras'));
        break;
      case !selectedNumber:
        toast.error(i18n.t('toast.error.please_select_number'));
        break;
      case selectedActionList.filter((item) => item.action === undefined)
        .length !== 0:
        toast.error(i18n.t('toast.error.please_select_action'));
        break;
      case selectedActionList.filter(
        (item) => item.type === 0 && (item.userIds || []).length === 0,
      ).length !== 0:
        toast.error(i18n.t('toast.error.please_select_user_for_action'));
        break;
      case selectedActionList.filter((item) => item.type === 1 && !item.iot)
        .length !== 0:
        toast.error(i18n.t('toast.error.please_add_iot_for_action'));
        break;
      case !alertName:
        toast.error(i18n.t('toast.error.please_add_name'));
        break;
      default:
        {
          setApiLoading(true);

          const rule: AlertProps = data.alert;

          const cameraId = filterCameraArray(
            rule.alert_rule_cameras,
            selectedCameraList,
            Number(slug),
          );

          const actions = filterActionArray(
            rule.alert_actions,
            selectedActionList,
          );

          const param = {
            id: Number(slug),
            site_id: globalSiteId,
            name: alertName,
            period: selectedInterval.value,
            number_of_observations: selectedNumber,
            customer_scenario_label_id: selectedScenario?.id,
            alert_rule_cameras: cameraId,
            alert_actions: actions,
            status: 'changed',
          };
          const createRule = await updateAlertRule(userToken, param);
          setApiLoading(false);
          if (createRule.status === 401) {
            onTokenSave('');
          }
          if (createRule.status === 200) {
            trackEvent('alert_update', {
              alertId: createRule.data,
              numCameras: param.alert_rule_cameras.length,
              scenario: selectedScenario?.name,
              numObservations: param.number_of_observations || 0,
              timeframe: param.period,
              channels: selectedActionList.map(
                (item) => item.action?.alert_channel.alert_type_name,
              ),
            });
            navigate('/alerts?action=manage');
          }
        }
        break;
    }
  }, [
    alertName,
    data,
    navigate,
    onTokenSave,
    selectedActionList,
    selectedCameraList,
    selectedInterval.value,
    selectedNumber,
    selectedScenario,
    slug,
    trackEvent,
    userToken,
  ]);

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onUpdateAlertRule();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [onUpdateAlertRule]);

  if (loading) {
    return (
      <div className="text-center empty-list">
        <Loader main />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center empty-list">
        <Loader main />
      </div>
    );
  }

  return (
    <div className="main create-alert-rule">
      <Breadcrumb
        title={`${i18n.t('smart_notifications.title')}`}
        toUrl={`${route.to !== route.from ? route.from : '/alerts?action=manage'}`}
      />
      <h5 className="mb-32">{i18n.t('update_notification_rule.title')}</h5>
      <Row>
        <Col className="container" md={12}>
          <Row>
            <Triggers
              className="action-item"
              cameraList={cameraList}
              selectedScenario={selectedScenario}
              selectedCameraList={selectedCameraList}
              onClickSelectedCameraList={(cameras) => {
                const cameraIds = cameras.map((item) => item.value);
                const filterArray = cameraList.filter(
                  (item) => cameraIds.indexOf(item.sql_id) !== -1,
                );
                setSelectedCameraList(filterArray);
              }}
              onScenario={(scenario) => setSelectedScenario(scenario)}
            />

            <hr className="mt-24 mb-24" />

            <ConditionsAction
              className="action-item"
              selectedNumber={selectedNumber}
              onNumber={(number) => setSelectedNumber(number)}
              selectedInterval={selectedInterval}
              onIntervalClick={(type, value) =>
                setSelectedInterval({ type, value })
              }
            />

            <hr className="mt-24 mb-24" />

            <MultiAlertAction
              className="action-item"
              selectedActionList={selectedActionList}
              onAction={(action) => setSelectedActionList(action)}
            />

            <hr className="mt-24 mb-24" />

            <NameOfAlertRuleAction
              className="action-item"
              alertName={alertName}
              onTextChange={(event) => setAlertName(event.target.value)}
            />

            <Col md={12} lg={7} className="action-item mt-5 d-flex">
              <BWButton
                title={i18n.t('button.cancel')}
                variant="outline"
                type="button"
                onClick={() => navigate(-1)}
              />

              <BWButton
                className="button-with-loader ms-12"
                disabled={apiLoading}
                loading={apiLoading}
                title={i18n.t('button.update_rule')}
                variant="primary"
                type="button"
                onClick={() => onUpdateAlertRule()}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
