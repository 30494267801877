/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import i18n from 'i18next';
import React from 'react';
import { Dropdown, Nav } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import { globalSiteId } from '../../apis/tokens';
import { ReactComponent as Logout } from '../../assets/nav/logout.svg';
import { ReactComponent as Profile } from '../../assets/nav/profile.svg';
import { ReactComponent as Workspace } from '../../assets/nav/workspace.svg';
import { useAuthContext } from '../../contextapi/AuthProvider';
import { usePersistentStorageContext } from '../../contextapi/PersistentStorageProvider';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import { useTrackEvent } from '../../hooks/track_event';
import { GRAY_LIGHT } from '../../scss/colors';
import { spacing } from '../../scss/spacing';
import { buttonCss, BWButton } from '../elements/BWButton';

export function UserProfileDropdown() {
  const navigate = useNavigate();
  const trackEvent = useTrackEvent();
  const { theme } = useThemeContext();
  const { user, logout } = useAuthContext();
  const { persistSiteId: saveSiteId } = usePersistentStorageContext();

  return (
    <div className="user-profile">
      <Dropdown drop="up">
        <Dropdown.Toggle
          variant={`${user?.isAdmin ? 'admin-user' : 'non-admin-user'}`}
          css={buttonCss}
          className="profile-toggle ph-no-capture"
        >
          {user?.email.substring(0, 1)}
        </Dropdown.Toggle>

        <Dropdown.Menu variant={theme} className="p-0">
          <div className={`${user?.isAdmin ? 'admin-user' : 'non-admin-user'}`}>
            {user?.username && (
              <p className="m-0 ph-no-capture">{user?.username}</p>
            )}
            <p className="m-0 ph-no-capture" style={{ fontSize: 12 }}>
              {user?.email}
            </p>
          </div>

          <p
            css={css`
              margin: 0;
              padding: ${spacing(1)} ${spacing(3)};
              font-size: 10px;
              background: ${GRAY_LIGHT};
            `}
          >
            {i18n.t('bottom_nav.working_sites')}
          </p>

          {user?.customer.sites.map((site) => {
            const active = site.id === Number(globalSiteId);
            return (
              <Nav.Link
                key={site.id}
                className={`nav-link dropdown-item justify-content-start ${active && 'active'}`}
                css={css`
                  &.nav-link {
                    margin: 0;
                    padding: 0 !important;
                    font-weight: 600;
                  }
                `}
                onClick={() => {
                  saveSiteId(site.id);
                  trackEvent('site_change');
                  navigate('/dashboard');
                }}
              >
                <BWButton
                  style={{ fontWeight: active ? '600' : '400' }}
                  isSimpleRounded={false}
                  variant="transparent"
                  title={site.name}
                  icon={Workspace}
                  trackAs="site_change"
                />
              </Nav.Link>
            );
          })}

          <p
            css={css`
              margin: 0;
              padding: ${spacing(1)} ${spacing(3)};
              font-size: 10px;
              background: ${GRAY_LIGHT};
            `}
          >
            {i18n.t('bottom_nav.account_access_control')}
          </p>
          <NavLink
            className="nav-link dropdown-item m-0 justify-content-start"
            to="/settings"
            css={css`
              &.nav-link {
                margin: 0;
                padding: 0 !important;
                font-weight: 600;
              }
            `}
          >
            <BWButton
              style={{ fontWeight: '400' }}
              isSimpleRounded={false}
              variant="transparent"
              title={i18n.t('button.profile_settings')}
              icon={Profile}
              trackAs={[
                'navigation_menu_click',
                {
                  menu_item: 'user_settings',
                },
              ]}
            />
          </NavLink>
          <Nav.Link
            className="dropdown-item m-0 justify-content-start"
            onClick={async () => {
              trackEvent('user_logout');
              await logout();
            }}
            css={css`
              &.nav-link {
                margin: 0;
                padding: 0 !important;
                font-weight: 600;
              }
            `}
          >
            <BWButton
              style={{ fontWeight: '400' }}
              isSimpleRounded={false}
              variant="transparent"
              title={i18n.t('button.log_out')}
              icon={Logout}
            />
          </Nav.Link>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
