import i18n from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createAlertRule } from '../apis/api-request';
import { globalSiteId } from '../apis/tokens';
import { Breadcrumb } from '../components/elements/Breadcrumb';
import { BWButton } from '../components/elements/BWButton';
import { ConditionsAction } from '../components/notification-actions/ConditionsAction';
import { MultiAlertAction } from '../components/notification-actions/MultiAlertAction';
import { NameOfAlertRuleAction } from '../components/notification-actions/NameOfAlertRuleAction';
import { Triggers } from '../components/notification-actions/Triggers';
import { useAuthContext } from '../contextapi/AuthProvider';
import { useCameraContext } from '../contextapi/CameraProvider';
import { useLocationHistoryContext } from '../contextapi/LocationHistoryProvider';
import { useTrackEvent } from '../hooks/track_event';
import { Scenario } from '../lib/features/scenario';
import { CameraProps } from '../typescript/camera/camera';
import { MultiActionProps } from '../typescript/smart-alert/smart-alert';

export function CreateAlertRulePage() {
  const { route } = useLocationHistoryContext();
  const { userToken, onTokenSave } = useAuthContext();
  const trackEvent = useTrackEvent();
  const { cameraList } = useCameraContext();

  // Navigation
  const navigate = useNavigate();

  // CameraAction
  const [selectedCameraList, setSelectedCameraList] = useState<
    Array<CameraProps>
  >([]);

  // ConditionsAction
  const [selectedScenario, setSelectedScenario] = useState<Scenario | null>(
    null,
  );
  const [selectedNumber, setSelectedNumber] = useState<number | null>(null);

  const [selectedActionList, setSelectedActionList] = useState<
    Array<MultiActionProps>
  >([]);

  // IntervalAction
  const [selectedInterval, setSelectedInterval] = useState<{
    type: number;
    value: number;
  }>({ type: 1, value: 3600 });

  // NameOfAlertRuleAction
  const [alertName, setAlertName] = useState<
    string | number | string[] | undefined
  >();

  // Loader
  const [apiLoading, setApiLoading] = useState<boolean>(false);

  const onCreateAlertRule = useCallback(async () => {
    switch (true) {
      case !selectedScenario:
        toast.error(i18n.t('toast.error.please_select_scenario'));
        break;
      case selectedCameraList.length === 0:
        toast.error(i18n.t('toast.error.please_select_cameras'));
        break;
      case !selectedNumber:
        toast.error(i18n.t('toast.error.please_select_number'));
        break;
      case selectedActionList.filter((item) => item.action === undefined)
        .length !== 0:
        toast.error(i18n.t('toast.error.please_select_action'));
        break;
      case selectedActionList.filter(
        (item) => item.type === 0 && (item.userIds || []).length === 0,
      ).length !== 0:
        toast.error(i18n.t('toast.error.please_select_user_for_action'));
        break;
      case selectedActionList.filter((item) => item.type === 1 && !item.iot)
        .length !== 0:
        toast.error(i18n.t('toast.error.please_add_iot_for_action'));
        break;
      case !alertName:
        toast.error(i18n.t('toast.error.please_add_name'));
        break;
      default:
        {
          setApiLoading(true);
          const alertActionArray = selectedActionList
            ?.filter((item) => item)
            .map((item) => {
              if (item.action) {
                if (item.type) {
                  return {
                    alert_site_channel_id: item.action.id,
                    alert_recipients: [{ value: item.iot }],
                  };
                }
                return {
                  alert_site_channel_id: item.action.id,
                  alert_recipients: item.userIds?.map((user) => ({
                    user_id: user,
                  })),
                };
              }
              return false;
            });

          const cameraId =
            selectedCameraList.length !== 0
              ? selectedCameraList.map((item) => ({ camera_id: item.sql_id }))
              : undefined;

          const param = {
            site_id: globalSiteId,
            name: alertName,
            period: selectedNumber === 1 ? 0 : selectedInterval.value,
            number_of_observations: selectedNumber,
            customer_scenario_label_id: selectedScenario?.id,
            alert_rule_cameras: cameraId,
            alert_actions: alertActionArray,
          };
          const createRule = await createAlertRule(userToken, param);
          setApiLoading(false);
          if (createRule.status === 401) {
            onTokenSave('');
          }
          if (createRule.status === 200) {
            trackEvent('alert_create', {
              alertId: createRule.data,
              numCameras: selectedCameraList.length,
              scenario: selectedScenario?.name,
              numObservations: param.number_of_observations || 0,
              timeframe: param.period,
              channels: selectedActionList.map(
                (item) => item?.action?.alert_channel.alert_type_name,
              ),
            });
            navigate('/alerts?action=manage');
          }
        }
        break;
    }
  }, [
    alertName,
    navigate,
    onTokenSave,
    selectedActionList,
    selectedCameraList,
    selectedInterval.value,
    selectedNumber,
    selectedScenario,
    trackEvent,
    userToken,
  ]);

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onCreateAlertRule();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [onCreateAlertRule]);

  return (
    <div className="main create-alert-rule">
      <Breadcrumb
        title={`${i18n.t('smart_notifications.title')}`}
        toUrl={`${route.to !== route.from ? route.from : '/alerts?action=manage'}`}
      />
      <h5 className="mb-32">{i18n.t('add_notification_rule.title')}</h5>
      <Row>
        <Col className="container" md={12}>
          <Row>
            <Triggers
              className="action-item"
              cameraList={cameraList}
              selectedScenario={selectedScenario}
              selectedCameraList={selectedCameraList}
              onClickSelectedCameraList={(cameras) => {
                const cameraIds = cameras.map((item) => item.value);
                const filterArray = cameraList.filter(
                  (item) => cameraIds.indexOf(item.sql_id) !== -1,
                );
                setSelectedCameraList(filterArray);
              }}
              onScenario={(scenario) => setSelectedScenario(scenario)}
            />

            <hr className="mt-24 mb-24" />

            <ConditionsAction
              className="action-item"
              selectedNumber={selectedNumber}
              onNumber={(number) => setSelectedNumber(number)}
              selectedInterval={selectedInterval}
              onIntervalClick={(type, value) =>
                setSelectedInterval({ type, value })
              }
            />

            <hr className="mt-24 mb-24" />

            <MultiAlertAction
              className="action-item"
              selectedActionList={selectedActionList}
              onAction={(action) => setSelectedActionList(action)}
            />

            <hr className="mt-24 mb-24" />

            <NameOfAlertRuleAction
              className="action-item"
              alertName={alertName}
              onTextChange={(event) => setAlertName(event.target.value)}
            />

            <Col md={12} className="action-item mt-5 d-flex">
              <BWButton
                title={i18n.t('button.cancel')}
                variant="outline"
                type="button"
                onClick={() => navigate(-1)}
              />

              <BWButton
                className="button-with-loader ms-12"
                disabled={apiLoading}
                loading={apiLoading}
                title={i18n.t('button.create_new_rule')}
                variant="primary"
                type="button"
                onClick={() => onCreateAlertRule()}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
