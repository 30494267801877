import i18n from 'i18next';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { Card } from 'react-bootstrap';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { MultiBarChartPlaceholder } from './MultiBarChartPlaceholder';
import { ScenariosLegend } from './ScenariosLegend';
import { BarTooltip } from './tools/BarTooltip';
import { useScenarioContext } from '../../../contextapi/ScenarioProvider';
import { useThemeContext } from '../../../contextapi/ThemeProvider';
import { useTimeRangeContext } from '../../../contextapi/TimeRangeProvider';
import { useNavigateToObservation } from '../../../hooks/charts';
import { ObservationSet } from '../../../hooks/graphql/chart';
import { useTrackEvent } from '../../../hooks/track_event';
import { ScenarioApiName, ScenarioName } from '../../../lib/features/scenario';
import {
  combineObservations,
  timeRangeOptions,
} from '../../../typescript/grouping/grouping-observation';

interface Props {
  data: ObservationSet[];
  cameraIds?: Array<number>;
  isLoading?: boolean;
  scenarioColors?: Partial<Record<ScenarioApiName, string>>;
}

export function MultiBarChart({
  data,
  scenarioColors,
  cameraIds,
  isLoading = false,
}: Props) {
  const { theme } = useThemeContext();
  const { getScenariosByObservationSets } = useScenarioContext();
  const { timeRange } = useTimeRangeContext();
  const trackEvent = useTrackEvent();
  const { navigateToObservation } = useNavigateToObservation();

  const [hiddenScenarios, setHiddenScenarios] = useState<Set<ScenarioName>>(
    new Set(),
  );

  const totalObservations = useMemo(
    () =>
      data.reduce(
        (total, { timeseries }) =>
          total + timeseries.reduce((acc, { count }) => acc + count, 0),
        0,
      ),
    [data],
  );

  const dateFormat = timeRange.isHourly
    ? 'DD MMM, YYYY, HH:mm'
    : 'DD MMM, YYYY';

  const scenariosByDate = useMemo(
    () => combineObservations(data, dateFormat),
    [data, dateFormat],
  );

  const observedScenarios = useMemo(
    () => getScenariosByObservationSets(data),
    [data, getScenariosByObservationSets],
  );

  return (
    <Card
      className={`card-${theme} border border-${theme} border-radius h-100 placeholder-glow`}
    >
      <Card.Body className="d-flex flex-column">
        <Card.Text className="text-center weight-600">
          {i18n.t('analytics_overview.daily_chart')}
        </Card.Text>
        {isLoading ? (
          <MultiBarChartPlaceholder />
        ) : (
          <ResponsiveContainer
            width="100%"
            minHeight={250}
            className="ph-no-capture"
          >
            <BarChart data={scenariosByDate}>
              <YAxis
                tickMargin={10}
                strokeOpacity={0.3}
                stroke={theme === 'light' ? '#1f2321' : '#ffffff'}
              />
              <XAxis
                dataKey="date"
                tickMargin={10}
                tickFormatter={(tick: string) =>
                  timeRange.isHourly
                    ? moment(tick).format('HH:mm')
                    : tick.slice(0, -6)
                }
                stroke={theme === 'light' ? '#1f2321' : '#ffffff'}
                strokeOpacity={0.3}
              />
              <CartesianGrid
                strokeDasharray="3 3"
                stroke={theme === 'light' ? '#cfd3cf' : '#6f7676'}
              />
              {observedScenarios.map((scenario) => (
                <Bar
                  key={scenario.name}
                  dataKey={scenario.name}
                  stackId="sameId"
                  hide={hiddenScenarios.has(scenario.name)}
                  fill={
                    scenarioColors?.[scenario.apiName as ScenarioApiName] ||
                    scenario.primaryColor
                  }
                  onClick={(_, index) => {
                    const startOfDay = moment(
                      scenariosByDate[index].date,
                    ).unix();
                    let endOfDay: number;
                    if (scenariosByDate.length - 1 !== index) {
                      endOfDay = moment(scenariosByDate[index + 1].date).unix();
                    } else {
                      endOfDay = moment(scenariosByDate[index].date)
                        .endOf('day')
                        .unix();
                    }
                    const scenarioIds = observedScenarios
                      .filter((scenario) => !hiddenScenarios.has(scenario.name))
                      .map((scenario) => scenario.id);

                    trackEvent('bar_chart_bar_click', {
                      time_range: timeRangeOptions[timeRange.text],
                      bar_label: scenariosByDate[index].date,
                    });

                    navigateToObservation({
                      timeRange: {
                        value: startOfDay,
                        end: endOfDay,
                      },
                      scenarioIds,
                      cameraIds,
                    });
                  }}
                  className="add-cursor"
                />
              ))}
              <Legend
                content={
                  <ScenariosLegend
                    onScenarioClick={(scenario) => {
                      const newScenarios = new Set(hiddenScenarios);
                      if (hiddenScenarios.has(scenario.name)) {
                        newScenarios.delete(scenario.name);
                      } else {
                        newScenarios.add(scenario.name);
                      }

                      setHiddenScenarios(newScenarios);
                    }}
                    hiddenScenarios={hiddenScenarios}
                    scenarioColors={scenarioColors}
                  />
                }
              />
              <Tooltip
                cursor={{ fill: 'transparent' }}
                content={<BarTooltip totalObservations={totalObservations} />}
              />
            </BarChart>
          </ResponsiveContainer>
        )}
      </Card.Body>
    </Card>
  );
}
