import React, { Navigate, Route, Routes } from 'react-router-dom';
import { ProtectedLayout } from '../layouts/ProtectedLayout';
import { AlertDetailsPage } from '../pages/AlertDetailsPage';
import { AlertsPage } from '../pages/AlertsPage';
import { CameraDetailsPage } from '../pages/CameraDetailsPage';
import { CamerasPage } from '../pages/CamerasPage';
import { CreateAlertRulePage } from '../pages/CreateAlertRule';
import { DashboardPage } from '../pages/DashboardPage';
import { ObservationDetailsPage } from '../pages/ObservationDetailsPage';
import { ObservationListPage } from '../pages/ObservationListPage';
import { ProfilePage } from '../pages/ProfilePage';
import { ScenarioDetailsPage } from '../pages/ScenarioDetailsPage';
import { SettingsPage } from '../pages/SettingsPage';
import { UpdateAlertRulePage } from '../pages/UpdateAlertRulePage';
import { withPageContext } from '../utils/trackingContext';

export function ProtectedRoutes() {
  return (
    <Routes>
      <Route element={<ProtectedLayout />}>
        <Route
          path="/dashboard"
          element={withPageContext(<DashboardPage />, 'dashboard_page')}
        />

        <Route
          path="/observations"
          element={withPageContext(
            <ObservationListPage />,
            'observations_page',
          )}
        />
        <Route
          path="/group-observations/:groupId"
          element={withPageContext(
            <ScenarioDetailsPage />,
            'scenario_details_page',
          )}
        />

        <Route
          path="/observation/:slug"
          element={withPageContext(
            <ObservationDetailsPage />,
            'observation_details_page',
          )}
        />
        <Route
          path="/cameras"
          element={withPageContext(<CamerasPage />, 'cameras_page')}
        />

        <Route
          path="/camera/:cameraId"
          element={withPageContext(
            <CameraDetailsPage />,
            'camera_details_page',
          )}
        />

        <Route
          path="/alerts"
          element={withPageContext(<AlertsPage />, 'alerts_page')}
        />
        <Route
          path="/alerts/create"
          element={withPageContext(
            <CreateAlertRulePage />,
            'create_alerting_rule_page',
          )}
        />
        <Route
          path="/alerts/edit/:slug"
          element={withPageContext(
            <UpdateAlertRulePage />,
            'edit_alerting_rule_page',
          )}
        />
        <Route
          path="/alerts/:slug"
          element={withPageContext(<AlertDetailsPage />, 'alert_details_page')}
        />

        <Route
          path="/settings"
          element={withPageContext(<SettingsPage />, 'settings_page')}
        />
        <Route
          path="/profile/:slug"
          element={withPageContext(<ProfilePage />, 'user_profile_page')}
        />

        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Route>
    </Routes>
  );
}
