import React from 'react';
import { Nav } from 'react-bootstrap';
import { ReactComponent as Light } from '../../assets/nav/moon.svg';
import { ReactComponent as Dark } from '../../assets/nav/sun.svg';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import { useTrackEvent } from '../../hooks/track_event';

export function ThemeToggleButton() {
  const { theme, changeTheme } = useThemeContext();
  const trackEvent = useTrackEvent();

  return (
    <Nav.Link
      onClick={() => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        trackEvent('theme_change', {
          theme: newTheme,
        });
        changeTheme(newTheme);
      }}
      style={{
        marginBottom: 0,
      }}
    >
      {theme === 'light' ? (
        <Light className="icon" />
      ) : (
        <Dark className="icon" />
      )}
    </Nav.Link>
  );
}
