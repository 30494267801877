import i18n from 'i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import { SettingsBlock } from './camera-settings/SettingsBlock';
import { ReactComponent as CancelIcon } from '../../assets/icons/cancel.svg';
import { ReactComponent as AddIcon } from '../../assets/icons/plus.svg';
import { useAuthContext } from '../../contextapi/AuthProvider';
import { useCameraContext } from '../../contextapi/CameraProvider';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import { groupScenariosByCategory } from '../../lib/features/scenario';
import { CameraScenariosModal } from '../cameras/settings/CameraScenariosModal';
import { CameraScheduleModal } from '../cameras/settings/CameraScheduleModal';
import { DangerZoneModal } from '../cameras/settings/danger-zone/DangerZoneModal';
import { RemoveCameraBlock } from '../cameras/settings/RemoveCameraBlock';
import { ResetCameraModal } from '../cameras/settings/ResetCameraModal';
import { BWButton } from '../elements/BWButton';
import { Loader } from '../elements/Loader';
import { ScenarioLabel } from '../elements/ScenarioLabel';

export const cameraSettingsModals = [
  'camera',
  'active_scenarios',
  'danger_zone',
  'schedule',
  'reset_camera',
  'remove_camera',
] as const;

type CameraSettingsModalName = (typeof cameraSettingsModals)[number];

interface Props {
  cameraId: number;
  show: boolean;
  defaultModal?: CameraSettingsModalName;
  onCloseClick: () => void;
}

export function CameraSettingsModal({
  cameraId,
  show,
  defaultModal = 'camera',
  onCloseClick,
}: Props) {
  const { theme } = useThemeContext();
  const { featureFlags } = useAuthContext();
  const { isCameraListLoading, cameraScenarios, setActiveCameraId } =
    useCameraContext();

  const [activeModal, setActiveModal] =
    useState<CameraSettingsModalName>(defaultModal);

  const scenariosByCategory = useMemo(
    () => groupScenariosByCategory(cameraScenarios),
    [cameraScenarios],
  );

  useEffect(() => {
    setActiveCameraId(cameraId);
  }, [cameraId, setActiveCameraId]);

  const showModal = (modalName: CameraSettingsModalName) => {
    setActiveModal(modalName);
  };

  const handleSubModalClose = () => {
    setActiveModal('camera');
  };

  const showDangerZoneButton = scenariosByCategory.length > 0;
  const showResetCameraButton = featureFlags.cameras.init_site;

  return (
    <>
      <Modal
        key="main"
        centered
        className="custom-modal bw-modal"
        contentClassName={theme}
        dialogClassName="modal-60vw"
        show={show && activeModal === 'camera'}
        onHide={onCloseClick}
      >
        <Modal.Header>
          <Modal.Title>{i18n.t('modal.camera_settings.title')}</Modal.Title>
          <BWButton
            icon={CancelIcon}
            variant="transparent"
            className="border-0"
            type="button"
            onClick={onCloseClick}
          />
        </Modal.Header>
        <Modal.Body className="d-flex flex-column justify-content-center">
          {isCameraListLoading ? (
            <Loader main className="mx-auto" />
          ) : (
            <Row>
              <SettingsBlock
                title={`${i18n.t('camera.active_scenarios.title')} (${cameraScenarios.length})`}
                description={i18n.t('camera.active_scenarios.body')}
                buttonLabel={i18n.t('modal.camera_settings.button.scenarios')}
                buttonIcon={AddIcon}
                onClick={() => showModal('active_scenarios')}
              >
                {cameraScenarios.length > 0 ? (
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '0.5rem',
                      marginBottom: '1rem',
                    }}
                  >
                    {cameraScenarios.map((scenario) => (
                      <ScenarioLabel
                        key={scenario.name}
                        scenario={scenario}
                        showTooltip
                      />
                    ))}
                  </div>
                ) : (
                  <p>
                    <i>{i18n.t('scenarios.no_active_scenarios.title')}</i>
                  </p>
                )}
              </SettingsBlock>

              {showDangerZoneButton && (
                <SettingsBlock
                  title={i18n.t('camera.danger_zone.title')}
                  description={i18n.t('camera.danger_zone.body')}
                  buttonLabel={i18n.t(
                    'modal.camera_settings.button.danger_zone',
                  )}
                  buttonIcon={AddIcon}
                  onClick={() => showModal('danger_zone')}
                />
              )}

              <SettingsBlock
                title={i18n.t('camera.schedule.title')}
                description={i18n.t('camera.schedule.body')}
                buttonLabel={i18n.t('modal.camera_settings.button.schedules')}
                buttonIcon={AddIcon}
                onClick={() => showModal('schedule')}
              />

              {showResetCameraButton && (
                <SettingsBlock
                  title={i18n.t('camera.reset_camera.title')}
                  description={i18n.t('camera.reset_camera.body')}
                  buttonLabel={i18n.t('button.reset_camera')}
                  onClick={() => showModal('reset_camera')}
                />
              )}

              <RemoveCameraBlock onCameraRemoved={onCloseClick} />
            </Row>
          )}
        </Modal.Body>
      </Modal>

      {activeModal === 'active_scenarios' && (
        <CameraScenariosModal
          key="active_scenarios"
          show
          onClose={handleSubModalClose}
        />
      )}

      {activeModal === 'schedule' && (
        <CameraScheduleModal
          key="schedule"
          show
          onClose={handleSubModalClose}
        />
      )}

      {activeModal === 'danger_zone' && (
        <DangerZoneModal key="danger_zone" show onClose={handleSubModalClose} />
      )}

      {activeModal === 'reset_camera' && showResetCameraButton && (
        <ResetCameraModal
          key="reset_camera"
          show
          onClose={handleSubModalClose}
        />
      )}
    </>
  );
}
