import { usePostHog } from 'posthog-js/react';
import { useCallback } from 'react';
import { useAuthContext } from '../contextapi/AuthProvider';
import {
  TrackingContextType,
  useTrackingContext,
} from '../contextapi/TrackingProvider';
import { TimeRangeOption } from '../typescript/grouping/grouping-observation';
import { isString } from '../utils/typeUtils';

type EventProperties = {
  button_click: {
    action?: string;
    button_text?: string;
  };
  switch_toggle: {
    title: string;
    value: boolean;
  };
  form_submit: { action?: string; form_name: string; success: boolean };

  tab_click: { tab_name: string };
  dropdown_select: {
    action: string;
    dropdown_value?: string | number | boolean;
  };
  image_click: { action?: string };

  language_change: { language: string };
  theme_change: { theme: string };

  modal_close: { modal: string };
  modal_confirm: { modal: string };

  checkbox_check: { action?: string };
  checkbox_uncheck: { action?: string };

  alert_create: {
    alertId: number;
    numCameras: number;
    scenario?: string;
    numObservations: number;
    timeframe: number;
    channels: Array<string | undefined>;
  };
  alert_update: {
    alertId: number;
    numCameras: number;
    scenario?: string;
    numObservations: number;
    timeframe: number;
    channels: Array<string | undefined>;
  };
  alert_delete: {
    alertId: number;
  };

  bar_chart_bar_click: {
    time_range: TimeRangeOption;
    bar_label: string;
  };
  bar_chart_legend_click: {
    // time_range: TimeRangeOption;
    legend_name: string;
  };
  donut_chart_legend_click: {
    time_range: TimeRangeOption;
    legend_name: string;
  };
  donut_chart_slice_click: {
    time_range: TimeRangeOption;
    slice_label: string;
  };

  camera_card_click: {
    action?: string;
  };
};
export type EventName = keyof EventProperties;

export type EventContext = {
  page: string;
  modal?: string;
};

export type EventPropertiesWithContext = {
  [K in EventName]: EventProperties[K] & {
    context?: TrackingContextType;
  } & Record<string, unknown>;
};

export const useTrackEvent = () => {
  const { user } = useAuthContext();
  const posthog = usePostHog();
  const trackingContext = useTrackingContext();

  const customerId = user?.customer.trackingId || '';

  const trackEvent = useCallback(
    <T extends EventName>(
      eventName: T | string,
      properties:
        | EventPropertiesWithContext[T]
        | object = {} as EventPropertiesWithContext[T],
    ) => {
      const context = [trackingContext.page, trackingContext.element]
        .filter(isString)
        .join(':');

      const pgProperties = {
        ...properties,
        customer_id: customerId,
        context,
      };

      posthog.capture(eventName, pgProperties);

      if (
        process.env.NODE_ENV === 'development' ||
        process.env.REACT_APP_POSTHOG_LOG_TO_CONSOLE === 'true'
      ) {
        // eslint-disable-next-line no-console
        console.log('[PH]', eventName, pgProperties);
      }
    },
    [customerId, trackingContext.page, trackingContext.element, posthog],
  );

  return trackEvent;
};
