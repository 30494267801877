import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function PostHogPageView() {
  const posthog = usePostHog();
  const location = useLocation();

  useEffect(() => {
    posthog.capture('$pageview', {
      $current_url: window.location.href,
    });

    if (
      process.env.NODE_ENV === 'development' ||
      process.env.REACT_APP_POSTHOG_LOG_TO_CONSOLE === 'true'
    ) {
      // eslint-disable-next-line no-console
      console.log('[PH] Pageview', window.location.href);
    }
  }, [location, posthog]);

  return null;
}
