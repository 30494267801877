import i18n from 'i18next';
import React, { FormEvent, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BWButton } from '../components/elements/BWButton';
import { InputField } from '../components/elements/InputField';
import { LoginContainer } from '../components/login/LoginContainer';
import { useAuthContext } from '../contextapi/AuthProvider';
import { useLocationHistoryContext } from '../contextapi/LocationHistoryProvider';
import { useThemeContext } from '../contextapi/ThemeProvider';
import { useTrackEvent } from '../hooks/track_event';

export const LoginPage = () => {
  const navigate = useNavigate();
  const { theme } = useThemeContext();
  const { route } = useLocationHistoryContext();
  const { login } = useAuthContext();
  const trackEvent = useTrackEvent();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      const onLogin = await login(email, password);
      trackEvent('user_login', {
        success: onLogin,
      });
      if (onLogin) {
        navigate(route.from);
      } else {
        toast.error(i18n.t('toast.wrong_user_details'));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoginContainer>
      <h5 className="mb-32">{i18n.t('login.body')}</h5>

      <Form onSubmit={handleLogin}>
        <InputField
          className="mb-12"
          controlId="formEmail"
          type="email"
          required
          title={`${i18n.t('input.email.title')}`}
          placeholder={`${i18n.t('input.email.placeholder')}`}
          onChange={(e) => setEmail(e.target.value)}
        />

        <InputField
          className="mb-12"
          type="password"
          controlId="formPassword"
          title={`${i18n.t('input.password.title')}`}
          placeholder={`${i18n.t('input.password.placeholder')}`}
          onChange={(e) => setPassword(e.target.value)}
        />

        <div className="mb-12">
          <Link
            to="/forgot-password"
            className={`${theme === 'dark' ? 'white-color' : 'dark-color'}`}
            style={{ textDecoration: 'none' }}
          >
            <BWButton
              title={i18n.t('button.forgot')}
              variant="transparent"
              style={{ padding: 0 }}
              trackingProperties={{
                action: 'open_forgot_password_page',
              }}
            />
          </Link>
        </div>

        <BWButton
          title={i18n.t('button.sign_in')}
          className="button-with-loader w-100"
          variant="primary"
          type="submit"
          disabled={loading}
          loading={loading}
          doNotTrack
        />
      </Form>
    </LoginContainer>
  );
};
