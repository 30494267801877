import 'moment/locale/es';
import 'moment/locale/en-gb';
import { usePostHog } from 'posthog-js/react';
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { merge } from 'ts-deepmerge';
import { usePersistentStorageContext } from './PersistentStorageProvider';
import { fileStatus } from '../apis/api-csv';
import { userLogin } from '../apis/api-request';
import { UserToken } from '../apis/utils';
import { useGetUserQuery } from '../hooks/graphql/user';
import { ClientUser } from '../typescript/observation/assignee';
import { ObservationProp } from '../typescript/observation/observation';
import {
  DEFAULT_FEATURE_FLAGS,
  excludeDemoAccounts,
  GqlCurrentUser,
  GqlFeatureFlags,
  isBuddywiseCustomer,
} from '../typescript/user/user';
import { isNull, isNumber, isString } from '../utils/typeUtils';

type AuthContextType = {
  user: GqlCurrentUser | undefined;
  isUserLoading: boolean;
  clientUsersList: ClientUser[];
  userToken: UserToken;
  featureFlags: GqlFeatureFlags;
  isCSVStatus: string | null;
  isAnnotatedObservation: (observation?: ObservationProp) => boolean;
  login: (email: string, password: string) => Promise<boolean>;
  logout: () => Promise<void>;
  refetchUser: ReturnType<typeof useGetUserQuery>['refetch'];
  setIsCSVStatus: (value: React.SetStateAction<string | null>) => void;
  onTokenSave: (token: string | null) => void;
};

const AuthContext = createContext<AuthContextType | null>(null);

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext must be used within a AuthProvider');
  }

  return context;
};

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const posthog = usePostHog();
  const [searchParams] = useSearchParams();
  const { userToken, siteId, persistUserToken, persistSiteId } =
    usePersistentStorageContext();
  const siteIdSearchParam = searchParams.get('siteId')
    ? Number(searchParams.get('siteId'))
    : undefined;
  const [isCSVStatus, setIsCSVStatus] = useState<string | null>(null);

  const {
    loading,
    error,
    data,
    refetch: refetchUser,
  } = useGetUserQuery({
    includeUsers: true,
  });

  const user = useMemo(() => data?.user.at(0), [data]);

  const onTokenSave = useCallback(
    (token: string | null) => {
      if (isString(token) && token.length > 0) {
        persistUserToken(token);
      } else {
        persistSiteId(null);
        persistUserToken(null);
      }
    },
    [persistSiteId, persistUserToken],
  );

  useEffect(() => {
    if (error?.message === 'Authentication hook unauthorized this request') {
      onTokenSave(null);
    }
  }, [error, onTokenSave]);

  const featureFlags = useMemo(
    () => merge(DEFAULT_FEATURE_FLAGS, user?.customer.feature_flags || {}),
    [user],
  );

  useEffect(() => {
    if (!user) {
      return;
    }

    posthog.identify(user.trackingId, {
      user_id: user.trackingId,
      customer_id: user.customer.trackingId,
      is_admin: user.isAdmin,
      phone_number_verified: user.phoneNoVerified,
      exclude_from_tracking: user.exclude_from_tracking || false,
      $somethingElse: 'something',
      testing: 'testing',
    });
    posthog.setPersonPropertiesForFlags(featureFlags);
    posthog.group('customer', `customer:${user.customer.trackingId}`);
    posthog.group('site', `site:${siteId}`);
  }, [user, siteId, posthog, featureFlags]);

  const clientUsersList = useMemo(() => {
    if (!user?.customer.users) {
      return [];
    }

    const { customer } = user;
    const { users } = user.customer;

    return isBuddywiseCustomer(customer)
      ? users
      : users.filter(excludeDemoAccounts);
  }, [user]);

  useEffect(() => {
    const currentUser = data?.user.at(0);
    if (currentUser) {
      const siteIds = currentUser.customer.sites.map((site) => site.id);
      if (isNumber(siteIdSearchParam) && siteIds.includes(siteIdSearchParam)) {
        persistSiteId(siteIdSearchParam);
      } else if (isNull(siteId)) {
        persistSiteId(Math.min(...siteIds));
      }
    }
  }, [data, siteIdSearchParam, siteId, persistSiteId]);

  useEffect(() => {
    const fetchData = async () => {
      const makeAPICall = async () => {
        try {
          const response = await fileStatus(userToken);
          const { files } = response.data;
          if (response.status === 401) {
            onTokenSave(null);
            setIsCSVStatus(null);
          } else if (
            Object.keys(files).length === 0 &&
            files.constructor === Object
          ) {
            setIsCSVStatus(null);
          } else if (files.CSV_EXPORT === 'pending') {
            setIsCSVStatus(files.CSV_EXPORT);
            setTimeout(makeAPICall, 2000);
          } else if (files.CSV_EXPORT && files.CSV_EXPORT === 'done') {
            setIsCSVStatus(files.CSV_EXPORT);
          } else {
            setTimeout(makeAPICall, 2000);
          }
        } catch (err) {
          setIsCSVStatus(null);
        }
      };

      makeAPICall();
    };

    if (user) {
      fetchData();
    }
  }, [isCSVStatus, user, userToken, onTokenSave]);

  useEffect(() => {
    const phEnabled = localStorage.getItem('ph_enabled') === 'true';
    if (user && featureFlags.enable_posthog !== phEnabled) {
      if (featureFlags.enable_posthog) {
        localStorage.setItem('ph_enabled', String(featureFlags.enable_posthog));
      } else {
        localStorage.removeItem('ph_enabled');
      }

      window.location.reload();
    }
  }, [user, featureFlags.enable_posthog, posthog]);

  const isAnnotatedObservation = useCallback(
    (observation?: ObservationProp) => {
      const minObservationId = featureFlags.observations.show_annotation;
      if (!observation || !isNumber(minObservationId)) {
        return false;
      }

      return observation.id < minObservationId;
    },
    [featureFlags.observations.show_annotation],
  );

  const context = useMemo(
    () => ({
      user,
      isUserLoading: loading,
      userToken,
      featureFlags,
      isCSVStatus,
      clientUsersList,
      setIsCSVStatus,
      login: async (email: string, password: string) => {
        const loginUser = await userLogin({ email, password });
        if (loginUser.status === 200) {
          onTokenSave(loginUser.data.token);
          toast.success(loginUser.data.message);
          refetchUser();
          return true;
        }
        return false;
      },
      logout: async () => {
        onTokenSave(null);
        posthog.reset();
      },
      refetchUser,
      onTokenSave,
      isAnnotatedObservation,
    }),
    [
      onTokenSave,
      refetchUser,
      user,
      userToken,
      clientUsersList,
      loading,
      isCSVStatus,
      featureFlags,
      posthog,
      isAnnotatedObservation,
    ],
  );

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};
