import i18n from 'i18next';
import React, { useMemo } from 'react';
import { Card } from 'react-bootstrap';
import {
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Text,
  Tooltip,
} from 'recharts';
import { DonutTooltip } from './tools/DonutTooltip';
import { useScenarioContext } from '../../../contextapi/ScenarioProvider';
import { useThemeContext } from '../../../contextapi/ThemeProvider';
import { useTimeRangeContext } from '../../../contextapi/TimeRangeProvider';
import { useNavigateToObservation } from '../../../hooks/charts';
import { ObservationSet } from '../../../hooks/graphql/chart';
import { useTrackEvent } from '../../../hooks/track_event';
import {
  getScenarioTitle,
  mapToScenarioName,
  ScenarioApiName,
  scenarioLabelNames,
} from '../../../lib/features/scenario';
import { timeRangeOptions } from '../../../typescript/grouping/grouping-observation';
import { isDefined, isNotNull } from '../../../utils/typeUtils';
import { CustomPlaceholder } from '../../elements/CustomPlaceholder';

type Props = {
  dataSets: ObservationSet[];
  scenarioColors?: Partial<Record<ScenarioApiName, string>>;
  cameraIds?: Array<number>;
  isLoading?: boolean;
};

export function DonutChart({
  dataSets,
  scenarioColors,
  cameraIds,
  isLoading = false,
}: Props) {
  const { theme } = useThemeContext();
  const { getScenarioByName, getScenarioByApiName } = useScenarioContext();
  const { timeRange } = useTimeRangeContext();
  const { navigateToGroupScenario, navigateToObservation } =
    useNavigateToObservation();
  const trackEvent = useTrackEvent();

  const chartData = useMemo(() => {
    const data = dataSets
      .map(({ id, timeseries, scenario_names }) => {
        const apiName = scenario_names[0];
        const scenarioName = mapToScenarioName(apiName);
        if (!scenarioName) {
          return null;
        }
        const observationCount = timeseries.reduce(
          (total, timeEntry) => total + timeEntry.count,
          0,
        );

        return { id, name: scenarioName, apiName, count: observationCount };
      })
      .filter(isNotNull);

    return data.sort(
      (a, b) =>
        scenarioLabelNames.indexOf(a.name) - scenarioLabelNames.indexOf(b.name),
    );
  }, [dataSets]);

  const totalObservations = chartData.reduce(
    (total, { count }) => total + count,
    0,
  );

  const mostObservations = chartData.reduce(
    (prev, current) => (prev && prev.count > current.count ? prev : current),
    chartData.at(0),
  );
  const mostObservedScenario = getScenarioByApiName(mostObservations?.apiName);
  const percentage = ((mostObservations?.count || 0) / totalObservations) * 100;

  const scenarioIds = chartData
    .map((item) => getScenarioByApiName(item.apiName))
    .filter(isDefined)
    .map((scenario) => scenario?.id);

  const renderCustomLabel = ({ cx, cy }: { cx: number; cy: number }) => (
    <g
      className="add-cursor"
      onClick={() => {
        navigateToObservation({
          timeRange,
          scenarioIds,
          cameraIds,
        });
      }}
    >
      <defs>
        <filter id="shadow" x="-50%" y="-50%" width="200%" height="200%">
          <feDropShadow
            dx="2"
            dy="2"
            stdDeviation="2"
            floodColor={theme === 'dark' ? '#6F7676' : '#1F2321'}
            floodOpacity="0.1"
          />
        </filter>
      </defs>
      <circle
        cx={cx}
        cy={cy}
        r={80}
        fill={theme !== 'dark' ? '#ffffff' : '#1F2321'}
        filter="url(#shadow)"
      />
      <Text
        x={cx}
        y={cy}
        textAnchor="middle"
        dominantBaseline="middle"
        className="total-observations-label"
        fontSize={24}
        fill={theme === 'dark' ? '#ffffff' : '#1F2321'}
        fontWeight="bold"
      >
        {totalObservations.toLocaleString()}
      </Text>
      <Text
        x={cx}
        y={cy + 25}
        textAnchor="middle"
        dominantBaseline="middle"
        className="total-observations-label"
        fontSize={14}
        fill={theme === 'dark' ? '#ffffff' : '#1F2321'}
        opacity={0.7}
      >
        {`${i18n.t('chart.title_observations')}`}
      </Text>
    </g>
  );

  return (
    <Card
      className={`card-${theme} border border-${theme} border-radius h-100 placeholder-glow`}
    >
      <Card.Body
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Card.Text className="text-center weight-600">
          {i18n.t('analytics_overview.total_observations')}
        </Card.Text>
        {isLoading ? (
          <CustomPlaceholder
            style={{
              margin: '5px auto',
              borderRadius: '50%',
              width: 240,
              height: 240,
            }}
          />
        ) : (
          <ResponsiveContainer
            aspect={1}
            className="ph-no-capture donut-chart"
            style={{
              maxWidth: 240,
            }}
          >
            <PieChart>
              <Pie
                data={chartData}
                dataKey="count"
                nameKey="name"
                innerRadius={100} // TODO: Make these dynamic
                outerRadius={120} // TODO: Make these dynamic
                paddingAngle={chartData.length === 1 ? 0 : 4}
                cornerRadius={chartData.length === 1 ? 0 : 2}
                labelLine={false}
                strokeWidth={0}
                label={renderCustomLabel}
                onClick={(dataValue) => {
                  const scenarioValue = getScenarioByName(dataValue.name);
                  const filteredChartData = chartData.find(
                    (scenario) => scenario.name === scenarioValue?.name,
                  );
                  if (scenarioValue && filteredChartData) {
                    trackEvent('donut_chart_slice_click', {
                      slice_label: scenarioValue?.name,
                      time_range: timeRangeOptions[timeRange.text],
                    });
                    navigateToGroupScenario({
                      timeRange,
                      scenarioGroupId: filteredChartData?.id,
                      cameraIds,
                    });
                  }
                }}
              >
                {chartData.map((chart) => {
                  const scenario = getScenarioByName(chart.name);
                  const apiName = scenario?.apiName as
                    | ScenarioApiName
                    | undefined;
                  return scenario ? (
                    <Cell
                      key={scenario.name}
                      fill={
                        (apiName && scenarioColors?.[apiName]) ??
                        scenario.primaryColor
                      }
                      className="add-cursor"
                    />
                  ) : null;
                })}
              </Pie>
              <Tooltip
                cursor={{ fill: 'transparent' }}
                content={<DonutTooltip totalObservations={totalObservations} />}
              />
            </PieChart>
          </ResponsiveContainer>
        )}

        {mostObservedScenario && (
          <div
            style={{
              flex: '1 1',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              cursor: 'pointer',
            }}
            onClick={() => {
              const scenarioGroupId = chartData.find(
                (scenario) => scenario.name === mostObservedScenario.name,
              );
              if (scenarioGroupId && scenarioGroupId.name) {
                trackEvent('donut_chart_legend_click', {
                  time_range: timeRangeOptions[timeRange.text],
                  legend_name: scenarioGroupId.name,
                });
                navigateToGroupScenario({
                  timeRange,
                  scenarioGroupId: scenarioGroupId.id,
                  cameraIds,
                });
              }
            }}
            aria-hidden
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {isLoading ? (
                <CustomPlaceholder
                  style={{
                    margin: '2px 0',
                    height: 16,
                    width: 60,
                  }}
                />
              ) : (
                <>
                  <div
                    className="me-1"
                    style={{
                      width: 10,
                      height: 10,
                      background:
                        (mostObservedScenario.apiName &&
                          scenarioColors?.[
                            mostObservedScenario.apiName as ScenarioApiName
                          ]) ??
                        mostObservedScenario.primaryColor,
                    }}
                  />
                  <p className="m-0 ">{i18n.t('analytics_overview.most')}</p>
                </>
              )}
            </div>
            {isLoading ? (
              <CustomPlaceholder
                style={{
                  margin: '2px 0',
                  height: 16,
                }}
              />
            ) : (
              <p className="m-0 ">{`${getScenarioTitle(mostObservedScenario)} — ${percentage.toFixed(2)}% (${mostObservations?.count})`}</p>
            )}
          </div>
        )}
      </Card.Body>
    </Card>
  );
}
