export type GqlFeatureFlags = {
  cameras: {
    init_site: boolean;
  };
  image: {
    show_thumbnail: boolean;
  };
  observations: {
    show_export_csv: boolean;
    show_annotation: number; // show annotation from this observation id
    show_delete?: boolean;
    show_labels_by_default?: boolean;
  };
  show_language_switcher: boolean;
  enable_posthog: boolean;
};

export const DEFAULT_FEATURE_FLAGS: GqlFeatureFlags = {
  cameras: {
    init_site: false,
  },
  image: {
    show_thumbnail: false, // TODO: ask Ata
  },
  observations: {
    show_delete: false, // TODO: ask Ata
    show_export_csv: false, // TODO: ask Ata
    show_labels_by_default: false,
    show_annotation: 0,
  },
  show_language_switcher: false,
  enable_posthog: false,
};

export type FeatureFlagOverrides = {
  dashoard_v2?: boolean;
  camera_details_page?: boolean;
  observation_quick_view_v2?: boolean;
};

export type GqlSite = {
  id: number;
  name: string;
  __typename?: 'sites';
};

export type GqlScenarioLabel = {
  id: number;
  name: string;
  custom_name: string | null;
  scenario: {
    name: string;
    __typename?: 'scenarios';
  };
  label: {
    name: string;
    __typename?: 'labels';
  };
  __typename?: 'customer_scenario_label';
};

export type GqlUser = {
  id: number | null;
  email: string;
  title: string | null;
  phoneNo: string | null;
  username: string | null;
  isAdmin: boolean | null;
  __typename?: 'users';
};

export type GqlCustomer = {
  id: number;
  sites: GqlSite[];
  users?: GqlUser[];
  feature_flags: GqlFeatureFlags;
  customer_scenario_labels: GqlScenarioLabel[];
  trackingId: string;
  __typename?: 'customers';
};

export type GqlCurrentUser = {
  id: number;
  email: string;
  title: string;
  phoneNo: string;
  phoneNoVerified: boolean;
  username: string;
  isAdmin: boolean;
  trackingId: string;
  customer: GqlCustomer;
  exclude_from_tracking: boolean;
  __typename?: 'user';
};

export function excludeDemoAccounts(user: GqlUser): boolean {
  return !user.email.includes('@buddywise.co');
}

export function isBuddywiseCustomer(customer: GqlCustomer): boolean {
  return customer.id === 7;
}
