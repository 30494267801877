import { z } from 'zod';
import { globalSiteId } from './tokens';
import { apiPost, APIResponse, axiosClient, UserToken } from './utils';
import { CameraUpdatePayload } from '../typescript/camera/camera';
import { TimePeriod } from '../typescript/datetime';

export const BUDDYWISE_API_URL = `${process.env.REACT_APP_BASE_URL}/api`;

export const userLogin = async (data: { email: string; password: string }) => {
  const response = await axiosClient
    .post('/v1/auth/login', data)
    .then((res) => res)
    .catch((error) => error.response);
  return response;
};

export const userResetPassword = async (data: object) => {
  const response = await axiosClient
    .post('/v1/auth/reset-password', data)
    .then((res) => res)
    .catch((error) => error.response);
  return response;
};

export type CameraAddPayload = {
  name: string;
  ip: string;
};

export const addCameras = async (token: UserToken, data: CameraAddPayload) => {
  const response = await axiosClient
    .post('/v2/camera-management/add/', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...(globalSiteId && { 'X-Site-Id': `${globalSiteId}` }),
      },
    })
    .then((res) => res)
    .catch((error) => error.response);
  return response;
};

export const doUpdateCamera = async (
  token: UserToken,
  data: CameraUpdatePayload,
) => {
  const response = await axiosClient
    .post('/v2/camera-management/update/', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...(globalSiteId && { 'X-Site-Id': `${globalSiteId}` }),
      },
    })
    .then((res) => res)
    .catch((error) => error.response);
  return response;
};

export type CameraRemovePayload = {
  id: number;
  ip_address: string;
};

export const doRemoveCamera = async (
  token: UserToken,
  data: CameraRemovePayload,
) => {
  const response = await axiosClient
    .post('/v2/camera-management/delete/', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...(globalSiteId && { 'X-Site-Id': `${globalSiteId}` }),
      },
    })
    .then((res) => res)
    .catch((error) => error.response);
  return response;
};

export type CameraInitPayload = {
  camera_id: number;
  scenario_id: number;
  ip_address: string;
};

export const doInitCamera = async (
  token: UserToken,
  data: CameraInitPayload,
) => {
  const response = await axiosClient
    .post('/v2/camera-management/initialize/', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...(globalSiteId && { 'X-Site-Id': `${globalSiteId}` }),
      },
    })
    .then((res) => res)
    .catch((error) => error.response);
  return response;
};

export const urlTempImageV2 = async (token: UserToken, data: object) => {
  const response = await axiosClient
    .post('/v2/images/presigned-url/', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...(globalSiteId && { 'X-Site-Id': `${globalSiteId}` }),
      },
    })
    .then((res) => res)
    .catch((error) => error.response);
  return response;
};

export const urlTempImage = async (token: UserToken, data: object) => {
  const response = await axiosClient
    .post('/v2/images/generate-presigned-url/', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...(globalSiteId && { 'X-Site-Id': `${globalSiteId}` }),
      },
    })
    .then((res) => res)
    .catch((error) => error.response);
  return response;
};

type HeatmapPayload = {
  camera_id: number;
  customer_scenario_label_id: number;
  image_url: string;
  timestamp: TimePeriod;
};

export const loadHeatMap = async (
  token: UserToken,
  data: HeatmapPayload,
): Promise<APIResponse<Blob>> => {
  const response = await axiosClient
    .post('/v2/camera-management/heatmap', data, {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`,
        ...(globalSiteId && { 'X-Site-Id': `${globalSiteId}` }),
      },
    })
    .then(
      (res) =>
        ({
          success: true,
          status: res.status,
          data: res.data,
        }) satisfies APIResponse,
    )
    .catch((error) => {
      console.error('Error fetching heatmap:', {
        error,
        response: error.response,
      });

      return {
        success: false,
        status: error.response?.status,
        error,
      } satisfies APIResponse;
    });

  return response;
};

export const createAlertRule = async (token: UserToken, data: object) => {
  const response = await axiosClient
    .post('/v2/alerts-next/create/', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...(globalSiteId && { 'X-Site-Id': `${globalSiteId}` }),
      },
    })
    .then((res) => res)
    .catch((error) => error.response);
  return response;
};

export const updateAlertRule = async (token: UserToken, data: object) => {
  const response = await axiosClient
    .post('/v2/alerts-next/update/', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...(globalSiteId && { 'X-Site-Id': `${globalSiteId}` }),
      },
    })
    .then((res) => res)
    .catch((error) => error.response);
  return response;
};

type SendOTPPayload = {
  phone_number: string;
};

const sendOTPResponseSchema = z.object({
  message: z.string(),
});
type SendOTPResponse = z.infer<typeof sendOTPResponseSchema>;

export const sendOTP = async (
  token: UserToken,
  data: SendOTPPayload,
): Promise<APIResponse<SendOTPResponse>> => {
  const response = apiPost<SendOTPPayload, SendOTPResponse>(
    '/v2/user/send-otp',
    token,
    data,
    sendOTPResponseSchema,
  );

  return response;
};

type VerifyOTPPayload = {
  otp_token: string;
};

const verifyOTPResponseSchema = z.object({
  message: z.string(),
});
type VerifyOTPResponse = z.infer<typeof verifyOTPResponseSchema>;

export const verifyOTP = async (
  token: UserToken,
  data: VerifyOTPPayload,
): Promise<APIResponse<VerifyOTPResponse>> => {
  const response = apiPost<VerifyOTPPayload, VerifyOTPResponse>(
    '/v2/user/verify-otp',
    token,
    data,
    verifyOTPResponseSchema,
  );

  return response;
};
