import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ProtectedRoutes } from './ProtectedRoutes';
import { useAuthContext } from '../contextapi/AuthProvider';
import { CacheProvider } from '../contextapi/CacheProvider';
import { CameraProvider } from '../contextapi/CameraProvider';
import { ScenarioProvider } from '../contextapi/ScenarioProvider';
import { TimeRangeProvider } from '../contextapi/TimeRangeProvider';
import { useScrollToTop } from '../hooks/window';
import { ForgotPasswordPage } from '../pages/ForgotPasswordPage';
import { LoadingPage } from '../pages/LoadingPage';
import { LoginPage } from '../pages/LoginPage';
import { withPageContext } from '../utils/trackingContext';

export function Router() {
  const { userToken, user, isUserLoading } = useAuthContext();
  useScrollToTop();

  if (!userToken) {
    return (
      <Routes>
        <Route
          path="/login"
          element={withPageContext(<LoginPage />, 'login_page')}
        />
        <Route
          path="/forgot-password"
          element={withPageContext(
            <ForgotPasswordPage />,
            'forgot_password_page',
          )}
        />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    );
  }

  if (isUserLoading || !user) {
    return <LoadingPage />;
  }

  return (
    <CacheProvider>
      <TimeRangeProvider>
        <ScenarioProvider>
          <CameraProvider>
            <ProtectedRoutes />
          </CameraProvider>
        </ScenarioProvider>
      </TimeRangeProvider>
    </CacheProvider>
  );
}
