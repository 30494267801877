/* eslint-disable react/no-danger */
import i18n from 'i18next';
import React, { FormEvent, useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { ReactComponent as CancelIcon } from '../../assets/icons/cancel.svg';
import { ReactComponent as DarkCameraBackground } from '../../assets/images/add-camera-dark.svg';
import { ReactComponent as CameraBackground } from '../../assets/images/add-camera.svg';
import { useCameraContext } from '../../contextapi/CameraProvider';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import { BWButton } from '../elements/BWButton';
import { InputField } from '../elements/InputField';

type Props = {
  show: boolean;
  onCloseClick: () => void;
};

export function AddCameraModal({ show, onCloseClick }: Props) {
  const { addCamera, gqlCameras } = useCameraContext();

  const [cameraIp, setCameraIp] = useState<string>('');
  const [cameraName, setCameraName] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);
  const [validated, setValidated] = useState<boolean>(false);

  const onAddCamera = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (!gqlCameras.some((camera) => camera.name === cameraName)) {
      setLoading(true);
      await addCamera({ name: cameraName, ip: cameraIp });
      setLoading(false);
      onCloseClick();
      setValidated(true);
    } else {
      toast.error(i18n.t('toast.error.camera_exist'));
    }
  };

  const { theme } = useThemeContext();
  return (
    <Modal
      centered
      contentClassName={theme}
      className="custom-modal add-camera"
      show={show}
      onHide={() => onCloseClick()}
    >
      <Modal.Header>
        <Modal.Title>{i18n.t('modal.camera.title')}</Modal.Title>
        <BWButton
          icon={CancelIcon}
          variant="transparent"
          className="border-0"
          type="button"
          onClick={() => onCloseClick()}
        />
      </Modal.Header>
      <Modal.Body className="px-0 pt-32 pb-32">
        <Row className="g-0">
          <Col md={12} className="text-center">
            {theme === 'light' ? (
              <CameraBackground width="100%" height="100%" />
            ) : (
              <DarkCameraBackground
                className="dark-image"
                width="100%"
                height="100%"
              />
            )}
          </Col>
          <Col md={12} className="px-3 text-center mt-32">
            <h6 className="mb-12">
              {i18n.t('modal.camera.add_new_camera.title')}
            </h6>

            <p
              className="mb-32"
              dangerouslySetInnerHTML={{
                __html: `${i18n.t('modal.camera.add_new_camera.body')}`,
              }}
            />

            <Form
              validated={validated}
              onSubmit={onAddCamera}
              style={{ maxWidth: 320 }}
              className="d-flex flex-column mx-auto"
            >
              <InputField
                className={`mb-12 input-${theme}`}
                type="text"
                controlId="formIpAddress"
                placeholder={`${i18n.t('input.ip_address.placeholder')}`}
                required
                value={cameraIp}
                onChange={(e) => setCameraIp(e.target.value)}
              />

              <InputField
                className={`mb-12 input-${theme}`}
                type="text"
                controlId="formCameraName"
                placeholder={`${i18n.t('input.camera_name.placeholder')}`}
                required
                value={cameraName}
                onChange={(e) => setCameraName(e.target.value)}
              />
              <BWButton
                title={i18n.t('button.add_camera')}
                className="button-with-loader"
                variant="primary"
                type="submit"
                loading={loading}
                disabled={loading}
              />
            </Form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
